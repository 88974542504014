@import "~bootstrap/scss/functions";
@import "variables.scss";

.start-page {
    .section {
        margin-top: 1rem;
        padding: 1rem;
        margin-bottom: 3rem;

        @media (max-width: 767px) {
            margin-left: -1rem;
            margin-right: -1rem;
        }

        h2 {
            display: flex;
            font-size: 1.1rem;
            color: $brand-800;
            font-weight: 500;
            text-transform: uppercase;
            font-family: "Boston Black";
            flex-direction: column;
            gap: 8px;

            @media (min-width: 767px) {
                flex-direction: row;
                justify-content: space-between;
            }

            a {
                color: $brand-800;
                text-decoration: none;
                font-size: 1rem;
                font-weight: normal;
                font-family: "Boston Regular";
                text-transform: capitalize;
            }
        }

        &.recommendation {
            color: $brand-800;
            background-color: $beige;
            padding: 1rem 2rem;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .icon {
                svg {
                    width: 35px;
                    height: 35px;
                }
            }

            h2 {
                font-size: 1.5rem;
                font-weight: 500;
                line-height: 2rem;
                text-transform: none!important;
            }

            b {
                font-weight: 500;
            }

            a {
                display: flex;
                justify-content: space-between;
                width: 100%;
                background-color: white;
                border: 3px solid $brand-500;
                padding: 10px 25px;
                padding-right: 12.5px;
                color: $brand-800;
                text-decoration: none;

                svg {
                    color: $brand-500;
                }
            }
        }

        &.bestseller {
            display: flex;
            flex-direction: column;

            h2 {
                color: black;
            }

            .row {
                flex-grow: 1;
                padding: 0;
                margin: 0;

                @media (min-width: 768px) {
                    padding-right: 3rem;
                }
            }

            .row > a {
                background-color: black;
                border-radius: 16px;
                overflow: hidden;

                background-size: cover;
                position: relative;
                & > div {
                    background-size: cover;
                    background-position: center center;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    background-color: rgba(0,0,0,.3);
                    background-image: none;
                    opacity: 1;
                    @include notMobile {
                        background-color: transparent;
                        opacity: 0;
                        transition: linear 0.3s opacity;
                        font-size: 24px;
                        color: $brand-800;
                        font-family: "Boston Black";
                        font-weight: 600;
                        &.red {
                            background-image: url('./StartPageAssets/placeholder_red.png');
                        }
                        &.rose {
                            background-image: url('./StartPageAssets/placeholder_rose.png');
                        }
                        &.white {
                            background-image: url('./StartPageAssets/placeholder_white.png');
                        }
                        &.sparkling {
                            background-image: url('./StartPageAssets/placeholder_sparkling.png');
                        }
                    }
                }
                &:hover > div {
                    @include notMobile {
                        opacity: 1;
                    }
                }
                

                width: 48%;
                margin: 1%;

                font-size: 18px;
                color: #fff;
                font-weight: bold;
                text-decoration: none;

                min-height: 160px;

                @media (max-width: 767px) {
                   min-height: 160px;
                }
            }
        }

        &.brand-wines {
            h1 {
                font-family: "Boston Black";
                text-transform: uppercase;
                
                font-size: 28px;
                @include notMobile {
                    font-size: 24px;
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            & > a > div {
                border-radius: 15px;
                position: relative;
                overflow: hidden;
                height: 250px;
            }
        }

        &.prices {
            .row > a {
                background-color: white;
                border-radius: 16px;
                border: 1px solid #EDEDED;

                &:hover {
                    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
                }

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;

                width: 23%;
                margin: 1%;

                font-size: 16px;
                color: #000;
                // font-weight: bold;
                text-decoration: none;
                // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                min-height: 111px;

                @media (max-width: 767px) {
                    min-height: 100px;
                    width: 48%;
                    margin: 1%;
                }

                svg {
                    margin: 5px 0;
                }
            }
        }

        .list-categories {
            display: flex;
            flex-direction: column;
            margin: 0 -1.5rem;

            .list-categories-item {
                display: flex;
                align-items: center;
                color: #666;
                text-decoration: none;

                padding: 15px 24px;
                border-bottom: 1px solid #eee;

                &:first-child {
                    border-top: 1px solid #eee;
                }


                svg.icon {
                    color: #c4c4c4;
                    width: 12px;
                    margin-right: 6px;
                }

                svg.arrow {
                    color: #666;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .best-rec-wrapper {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;

            .recommendation,
            .bestseller {
                flex-grow: 1;
                width: 50%;
            }
        }

        .section {
            padding-left: 0;
            padding-right: 0;
        }
    }
}